<template>
    <div>
        <page-header
            data-testid="close-account-keep-card-header"
            show-back
            @onClose="$router.go(-1)"
        />
        <div class="container">
            <div data-testid="close-account-keep-card-text">
                <h5>
                    {{ $t('pages.closeAccount.keepCard.text') }}
                </h5>
            </div>
            <div data-testid="close-account-keep-card-subtext">
                <span v-html="$t('pages.closeAccount.keepCard.subText', { limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit) })" />
            </div>
            <div class="card card-shadow rounded-3 mt-2 p-3">
                <div class="text-center">
                    <img
                        data-testid="close-account-keep-card-image"
                        src="@/assets/images/pages/card/BlankCardVisa.jpg"
                        width="100%"
                        alt="Sample VISA Card"
                    >
                </div>

                <div class="d-flex flex-column mt-2">
                    <div class="d-inline-flex justify-content-between align-items-center w-100">
                        <h4 class="fw-bold mb-0">
                            {{ toFormattedUSDNoCents(this.limitedModeCreditLimit) }}
                        </h4>
                        <h4 class="fw-bold mb-0">
                            {{ formatApr(this.apr / 100) }}
                        </h4>
                    </div>

                    <div class="d-inline-flex justify-content-between align-items-center w-100">
                        <span class="text-muted">Credit Limit</span>
                        <span class="text-muted">Variable APR</span>
                    </div>
                </div>

                <base-button
                    button-classes="btn btn-primary mt-2"
                    data-testid="close-account-keep-card-yes-button"
                    @click="handleClickYesButton"
                >
                    {{ $t('pages.closeAccount.keepCard.yesBtnText') }}
                </base-button>

                <div class="text-center text-muted small mt-2 mb-0">
                    By keeping your card, you consent to the updated
                    <a
                        data-testid="close-account-keep-account-agreement-download"
                        @click="getDocumentAndOpen('AccountAgreementHomeLimitedMode')"
                        href="#"
                    >Terms and Conditions</a>
                    <span
                        v-show="isDownloadingAccountAgreement"
                        class="spinner-border spinner-border-sm text-secondary mx-0"
                    />. You also agree to our
                    <a
                        data-testid="close-account-keep-e-sign-consent-link"
                        href="https://aven.com/docs/ESIGNConsent.pdf"
                        target="_blank"
                    >E-SIGN Consent</a>,
                    <a
                        data-testid="close-account-keep-privacy-policy-link"
                        href="https://aven.com/privacy"
                        target="_blank"
                    >Privacy Policy</a>, and
                    <a
                        data-testid="close-account-keep-terms-of-use-link"
                        href="https://aven.com/docs/TermsOfUse.pdf"
                        target="_blank"
                    >Terms of Use</a>.
                </div>

                <div
                    v-show="errorDownload"
                    class="alert alert-warning text-center small mt-1"
                    role="alert"
                >
                    <span>
                        {{ errorDownload }}
                    </span>
                </div>
            </div>

            <div class="my-2 text-center">
                <span
                    class="text"
                    data-testid="close-account-keep-card-no-button"
                    style="cursor: pointer"
                    @click="handleClickNoButton"
                >
                    <u>{{ $t('pages.closeAccount.keepCard.noBtnText') }}</u>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { RouteNames } from '@/routes/router'
    import { mapActions, mapGetters } from 'vuex'
    import { AccountClosureReason, AvenAccountStanding } from '@/store'
    import BaseButton from '@/components/base/BaseButton'
    import { logger } from '@/utils/logger'
    import { generateLegalDocument, getLegalDocumentDownload } from '@/services/api'
    import { isSafari } from '@/utils/parseUserAgents'
    import format from '@/mixins/format'

    export default {
        name: 'CloseAccountPrompt',
        mixins: [navigation, format],
        components: {
            PageHeader,
            BaseButton,
        },
        async mounted() {
            if (this.isAccountPendingCloseOrPayoff) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                return
            }
            if (
                this.isLimitedMode ||
                (this.closeAccountReason !== AccountClosureReason.REFI &&
                    this.closeAccountReason !== AccountClosureReason.CASH_OUT_REFI &&
                    this.closeAccountReason !== AccountClosureReason.SELLING_HOME)
            ) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PERMANENT })
                return
            }
            this.$logEvent('view_close_account_keep_card')
        },
        data() {
            return {
                errorDownload: '',
                isDownloadingAccountAgreement: false,
            }
        },
        computed: {
            ...mapGetters({
                accountStanding: 'avenAccountStanding',
                closeAccountReason: 'closeAccountReason',
                apr: 'apr',
                limitedModeCreditLimit: 'limitedModeCreditLimit',
                isWebView: 'isWebView',
                isLimitedMode: 'isLimitedMode',
            }),
            isAccountPendingCloseOrPayoff: function () {
                return this.accountStanding === AvenAccountStanding.pendingClose || this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
            },
        },
        watch: {
            isAccountPendingCloseOrPayoff: async function (newValue, oldValue) {
                if (newValue) {
                    logger.info(`isAccountPendingCloseOrPayoff changed from ${oldValue} -> ${newValue}`)
                    await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                    return
                }
            },
        },
        methods: {
            ...mapActions(['updateCloseAccountKeepCard']),
            getDocumentAndOpen: async function (docType) {
                this.$logEvent('click_close_account_limited_mode_account_agreement_download')

                this.errorDownload = ''
                this.isDownloadingAccountAgreement = true
                try {
                    logger.info(`Generating new legal doc: ${docType}...`)
                    await generateLegalDocument(docType)
                    logger.info(`Generating new legal doc: ${docType}...Done`)
                    if (this.isWebView) {
                        logger.info(`native webview, get limited_mode account agreement`)
                        const link = `/legal/document?docType=${docType}`
                        logger.info(`native webview, set window.location.href to ${link}`)
                        window.location.href = link
                    } else {
                        logger.info(`browser, download limited_mode account agreement`)
                        const response = await getLegalDocumentDownload(docType)
                        logger.info(`browser, download limited_mode account agreement completed`)
                        const file = new Blob([response.data], { type: 'application/pdf' })
                        const fileURL = URL.createObjectURL(file)
                        if (isSafari()) {
                            window.location.href = fileURL
                        } else {
                            window.open(fileURL, '_blank')
                        }
                    }
                } catch (e) {
                    this.errorDownload = 'Failed to download'
                } finally {
                    this.isDownloadingAccountAgreement = false
                }
            },
            handleClickYesButton: async function () {
                this.$logEvent('click_close_account_keep_card_yes')
                this.updateCloseAccountKeepCard(true)
                await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
            },
            handleClickNoButton: async function () {
                this.$logEvent('click_close_account_keep_card_no')
                this.updateCloseAccountKeepCard(false)
                await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_PERMANENT })
            },
        },
    }
</script>

<style lang="scss" scoped>
    a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: $blue;
            transition: all 0.3s;
        }
    }
</style>
